@import 'src/styles';

.wrapper {
  width: calc(100% + 10px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .method {
    display: flex;
    overflow: hidden;
    height: 75px;
    background-color: $injectedPaymentMethodColor;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    position: relative;
    margin: 5px;
    padding: 10px;
    flex-grow: 1;

    .imgContainer {
      width: 85px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .applepay,
    .applepay-base {
      background-repeat: no-repeat;
      background-size: contain;
      background-image: image-set("images/apple.webp" 1x, "images/apple@2x.webp" 2x, "images/apple@3x.webp" 3x);
      width: 50px;
      height: 21px;
      display: $injectedPaymentMethodLight;
    }

    .applepay-dark {
      background-repeat: no-repeat;
      background-size: contain;
      background-image: image-set("images/apple-dark.webp" 1x, "images/apple-dark@2x.webp" 2x, "images/apple-dark@3x.webp" 3x);
      width: 50px;
      height: 33px;
      display: $injectedPaymentMethodDark;
    }

    .paypal,
    .paypal-base {
      background-repeat: no-repeat;
      background-size: contain;
      background-image: image-set("images/paypal.webp" 1x, "images/paypal@2x.webp" 2x, "images/paypal@3x.webp" 3x);
      width: 80px;
      height: 20px;
      display: $injectedPaymentMethodLight;
    }

    .paypal-dark {
      background-repeat: no-repeat;
      background-size: contain;
      background-image: image-set("images/paypal-dark.webp" 1x, "images/paypal-dark@2x.webp" 2x, "images/paypal-dark@3x.webp" 3x);
      width: 62px;
      height: 20px;
      display: $injectedPaymentMethodDark;
    }

    .card,
    .card-base {
      background-repeat: no-repeat;
      background-size: contain;
      background-image: image-set("images/card.webp" 1x, "images/card@2x.webp" 2x, "images/card@3x.webp" 3x);
      width: 36px;
      height: 24px;
      display: $injectedPaymentMethodLight;
    }

    .card-dark {
      background-repeat: no-repeat;
      background-size: contain;
      background-image: image-set("images/card-dark.webp" 1x, "images/card-dark@2x.webp" 2x, "images/card-dark@3x.webp" 3x);
      width: 36px;
      height: 24px;
      display: $injectedPaymentMethodDark;
    }

    .googlepay,
    .googlepay-base {
      background-repeat: no-repeat;
      background-size: contain;
      background-image: image-set("images/google.webp" 1x, "images/google@2x.webp" 2x, "images/google@3x.webp" 3x);
      width: 76px;
      height: 25px;
      display: $injectedPaymentMethodLight;
    }

    .googlepay-dark {
      background-repeat: no-repeat;
      background-size: contain;
      background-image: image-set("images/google-dark.webp" 1x, "images/google-dark@2x.webp" 2x, "images/google-dark@3x.webp" 3x);
      width: 58px;
      height: 32px;
      display: $injectedPaymentMethodDark;
    }

    .applepay-base,
    .card-base,
    .paypal-base,
    .googlepay-base {
      display: flex;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .bottom {
      margin-top: 5px;
    }
  }

  .check {
    display: none;
    stroke: $injectedPaymentMethodArrowColor;
    width: 12px;
    height: 9px;
  }

  .active {
    border-color: $injectedPaymentMethodActiveColor;

    .check {
      display: flex;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: $injectedPaymentMethodCheckActiveColor;
      position: absolute;
      top: 3px;
      right: 3px;
      align-items: center;
      justify-content: center;

      .icon {
        width: 9px;
        height: 6px;
      }
    }
  }
}

.base {
  .method {
    background-color: $white;
  }

  .check {
    stroke: $injectedPaymentPrimaryColor;
  }

  .active {
    border-color: $injectedPaymentPrimaryColor;

    .check {
      background: #ECF4F3;
    }
  }
}
