$manageMainColor: #000000;
$manageAccentColor: $primaryColor;

$manageCPPrimaryColor: $mineShaft;
$manageCPSecondaryColor: $secondaryColor;

$manageBg: none;
$manageTextareaBorder: $primaryColor;
$manageTextareaBg: $manageMainColor;
$manageCheckmark: $primaryColor;
$manageCheckboxBg: rgba($primaryColor, 0.7);
$manageDarkColor: rgba($white, 0.7);
$manageCheckboxChecked: $manageAccentColor;
$manageModalBg: $white;
$manageModalCloseIcon: #000000;
$manageBorderColor: rgba(255, 255, 255, 0.1);
$manageSubUpdatingBadgeBg: #F9D05D;

$manageCurrentPlanBg: white;