@import "src/styles";

.root {
  position: absolute;
  z-index: 1;

  .container {
    display: flex;
    align-items: center;
    top: 0;
    left: 16px;
    transform: translate(0, -50%);
    background-color: $plansBadgeColor;
    padding: 8px 20px;
    border-radius: 16px;
    color: $white;
    font-weight: $fwb;
  }

  .discount {
    font-size: $fontSize14;
    line-height: 1;
  }

  .timerContainer {
    display: flex;
    align-items: flex-end;
    line-height: 1;
  }

  .text {
    font-size: $fontSize12;
    white-space: pre-line;
  }

  .timerText {
    color: $white;
    font-size: $fontSize14;
    margin-left: 5px;
  }

  &.centered {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
      left: auto;
    }
  }

  &.disabled {
    .container {
      background-color: $plansDisabledBgColor;
      color: $plansDisabledTextColor;
      font-weight: 700;
    }
  }
}
