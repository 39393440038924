@import "./colors";
@import "./variables";
$primaryColor: #ee8f61;
$secondaryColor: #16786c;

@import "src/styles/colors";

@import "../../manage-subscription/src/manage/styles/theme";
@import "../../manage-subscription/src/terminate/styles/theme";

@import "../../chat-flow/src/styles/theme";

$payModalPrimaryColor: var(--primaryColor);
$payModalSecondaryColor: var(--secondaryColor);

$trans: transparent;

$mainBgColor: #f7f7f7;

$colorError: #f24b57;
$colorBorderField: rgba(184, 185, 195, 0.3);
$inputColor: $gray;
$inputCaretColor: $gray;
$checkboxMarkBackground: var(--primaryColor);
$checkboxMarkCheckedBackground: var(--primaryColor);

$paymentDiscount: #FD5347;

$noteBlockBgColor: $mainBgColor;

$engVersionPrimary: $primaryText;

// questions
$questionTitleBgColor: $white;
$questionTitleColor: $mineShaft;
$questionSubTitleColor: $mineShaft;
$questionUpTitleColor: $mineShaft;
$questionActiveTitleColor: var(--primaryColor);
$questionActiveTitleBorderColor: var(--primaryColor);
$questionButtonBgColor: #142b35;
$questionTitleFontWeight: $fwb;
$quotesIconColor: #EBEBEB;
$questionAnswerSubtitleColor: $mineShaft;

$fontFamilyPhilosopher: Philosopher, sans-serif;

//shadow & opacity
$blackWithOpacity: rgba(0, 0, 0, 0.5);
$grayOpacity: rgba(45, 45, 45, 0.4);
$boxShadowColor: rgba(183, 181, 170, 0.4);
$boxShadow: 0 1px 4px 0 rgba(183, 181, 170, 0.4);
$boxShadowTitle: 0 1px 2px rgba(0, 0, 0, 0.2);
$shadowColor: rgba(183, 181, 170, 0.3);

// links
$linkColor: #2879d8;

$fontFamily: Open Sans, sans-serif;

$transition: all 0.3s ease;

// button
$buttonBackground: var(--primaryColor);
$disabledButtonBg: $grayLight;
$disabledButtonColor: $gray;
$buttonFontFamily: $fontFamily;
$buttonFontSize: $fontSize22;
$buttonFontWeight: $fwb;

// title
$titleH1Color: var(--primaryColor);
$titleH1FontSize: $fontSize26;
$titleH1FontFamily: $fontFamily;
$titleH1FontWeight: $fwb;

$titleH2Color: var(--titleH2Color);
$titleH2FontSize: $fontSize24;
$titleH2FontFamily: $fontFamily;
$titleH2FontWeight: $fwb;

$titleH3Color: $mineShaft;
$titleH3FontSize: $fontSize22;
$titleH3FontFamily: $fontFamily;
$titleH3FontWeight: $fwb;
$textColor: $primaryText;
$titlePaymentModalSecondary: var(--injectedPaymentPrimaryColor);
$titlePaymentModal: #2D2D2D;

// preloader
$preloaderColor: var(--primaryColor);
$preloaderBg: rgba(255, 255, 255, 0.5);

// subscription
$plansSectionTitle: $mineShaft;
$plansFeatureTextColor: $primaryText;
$plansPrimaryBg: $white;
$plansInactiveBg: $white;
$plansPrimaryColor: $secondaryColor;
$plansInactiveColor: lighten(desaturate($plansPrimaryColor, 40), 60);
$plansPrimaryBorderColor: $secondaryColor;
$plansInactiveBorderColor: $trans;
$plansPrimaryTitleColor: $secondaryColor;
$plansInactiveTitleColor: inherit;
$plansPrimaryTextColor: $titleH3Color;
$plansInactiveTextColor: inherit;
$plansPrimaryPriceColor: $white;
$plansInactivePriceColor: lighten(desaturate($plansPrimaryColor, 40), 20);
$plansBadgeColor: var(--primaryColor);
$plansBadgeColorLight: var(--primaryColorLight);
$plansDisabledBgColor: #dddddd;
$plansDisabledTextColor: #2d2d2d66;
$fixedButtonBoxBg: $white;
$fixedButtonDiscountColor: $secondaryColor;
$policyCheckBoxTextColor: $mineShaft;
$reservedPriceColor: $secondaryColor;
$reservedPriceColorLight: var(--secondaryColorLight);

$radioPlansInactiveBg: transparent;
$radioPlansInactiveBorderColor: #1e3b48;
$radioPlansActiveBg: #23292b;
$radioPlansActiveBorderColor: $white;
$radioPlansActiveTitleColor: $secondaryColor;
$radioPlansActiveColor: var(--primaryColor);
$radioPlansPriceActiveColor: $secondaryColor;
$radioPlansWeekInactiveBg: var(--primaryColor);
$radioPlansColor: $white;

//special offer
$soDiscountBlockBg: #e3eeeb;
$soDiscountImageBg: #69a79b1a;
$soDiscountAccentTextColor: var(--primaryColor, $primaryColor);

$soReservedPriceBg: #2e897f;

$soInfoAccentTextColor: #2e897f;
$soInfoOfferTextBg: #F2F7F6;

// select payment method
$spmTitleColor: #f2e6c4;
$spmSelectButtonNotActiveBg: #1e3b48;
$spmSelectButtonNotActiveBorder: #376478;
$spmSelectButtonNotActiveColor: rgba(255, 255, 255, 0.8);
$spmSelectButtonActiveBg: #ffffff;
$spmSelectButtonActiveBorder: #ffffff;
$spmSelectButtonActiveColor: #142b35;
$spmSelectIconActiveColor: #27baa7;
$spmDownloadsBg: #1e3b48;
$spmOrBg: #142b35;
$spmDownloadsLogoColor: #ffffff;
$spmDownloadsFooterColor: #ffffff;
$spmDownloadsFooterLineColor: #2b5567;
$spmPaymentDescriptionColor: rgba(255, 255, 255, 0.4);
$spmCardButtonBg: linear-gradient(2.59deg, #00eedb -77.77%, #27baa7 144.46%),
  #ee8f61;
$spmCardButtonColor: #ffffff;
$spmCardLabelColor: #ffffff;
$spmFontFamily: $fontFamily;

// full payment modal
$fpmBackgroundColor: #1e3b48;
$fpmFontFamily: $fontFamily;

//terms-menu
$sideBarDarkButtonColor: #f2e6c4;
$sideBarLightButtonColor: #2D2D2D;
$sideBarDarkBgColor: #1e3b48;
$sideBarLightBackDropBg: rgba(47, 47, 47, 0.8);
$sideBarDarkBackDropBg: rgba(21, 43, 53, 0.8);

// stars section
$storeStatsBlockBorder: 1px dashed #cbe7e2;
$storeStatsStarColor: var(--primaryColor);
$feedbackStarColor: var(--primaryColor);

// signboard
$warningTextColor: #ee8f61;
$warningBgColor: #fdeee4;

$successTextColor: #2e897f;
$successBgColor: #ecf4f3;

$errorTextColor: #c56554;
$errorBgColor: #faf1f0;

// progressbar
$progressBarBgColor: #ebebeb;
$progressBarActiveColor: var(--primaryColor);
$stepsCounterColor: $mineShaft;
$backArrowButtonColor: $mineShaft;

// magic progress
$magicGoalsIconColor: var(--primaryColor);
$magicTextsColor: $mineShaft;


// email
$emailNotificationIconColor: var(--primaryColor);
$emailInputColor: var(--primaryColor);
$emailBottomPartBgColor: $white;
$emailTitleColor: $mineShaft;
$emailNotificationColor: $mineShaft;
$emailInputBgColor: #F7F7F7;
$emailInputTextColor: rgba($mineShaft, .8);

$mailCheckboxBorderColor: $white;
$mailCheckboxTextColor: $white;

// feedbacks
$feedbackBackgroundColor: $white;
$feedbackTextsColor: $mineShaft;

// injected payment method
$injectedPaymentPrimaryColor: #499b92;
$injectedPaymentMethodActiveColor: #499b92;
$injectedPaymentMethodCheckActiveColor: #ecf4f3;
$injectedPaymentMethodColor: $white;
$injectedPaymentMethodArrowColor: #499b92;
$injectedPaymentMethodDark: none;
$injectedPaymentMethodLight: flex;

// fixed-wrapper
$fixedWrapperBgColor: #23535e;

// trials left
$trialsLeftBadgeColor: $white;
$trialsLeftBadgeBg: var(--primaryColor);
$trialsLeftTextColor: var(--primaryColor);

// payment safe
$paymentSafeDark: none;
$paymentSafeLight: inline-block;
$paymentSafeColor: $mineShaft;

// cookie modal

$cookieModalIcon: #F2E6C4;
$cookieModalTextColor: $white;
$cookieModalBg: #2D5668;
$cookieModalPrimary: #14D5C2;
$cookieModalFootnoteText: rgba(255, 255, 255, 0.70);
$cookieModalAcceptColor: $white;

// switch

$switchBgOff: #152B35;
$switchBgOn: #14D5C2;
$switchMarker: $white;

// users joined

$usersJoinedBorder: 1.2px solid #FDEEE4;
$usersJoinedTextColor: $mineShaft;
$usersJoinedBoldColor: #499B92;
$usersJoinedStarColor: #F9D05D;
$usersJoinedAvaBorderColor: $white;