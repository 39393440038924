@import 'src/styles';

.wrapper {
  margin-bottom: 1vh;

  .price {
    font-family: $fontFamily;
    font-size: $fontSize15;
    font-weight: $fwsb;
    line-height: 1.46;
    letter-spacing: normal;
  }

  .activePrice {
    margin-bottom: 4px;
    color: rgba($secondaryColor, 0.7);
  }

  .discount {
    color: rgba($secondaryColor, 0.9);
  }

  .notActivePrice {
    color: $primaryText;
  }
}