:root {
  --primaryColor: #8652FF;
  /* Main */
  --primaryColorLight: #8652FF75;
  /* Tint */
  --secondaryColor: #41CFD1;
  /* Accent */
  --secondaryColorLight: #41CFD175;
  --primaryText: #2d2d2d;
  /* Font */
  --mainBg: #f7f7f7;
  /* Background */
  --primaryTextLight: #2d2d2d40;
  --titleText: #6a52ff;
  --secondaryText: white;
  --secondaryBg: white;
  /* Quetions */
  --feedbackStarColor: var(--primaryColor);
  --buttonBackground: var(--primaryColor);
  --featureYouGetBg: var(--primaryColorLight);
  --plansInactiveColor: var(--primaryColorLight);
  --checkboxMarkCheckedBackground: var(--primaryColor);
  --checkboxMarkCheckedBackground: var(--primaryColor);
  --injectedPaymentPrimaryColor: var(--buttonBackground);
  --moneyBackTitleColor: var(--titleText);
  --titleH2Color: var(--primaryText);

  --soDiscountBlockBg: #F3EEFF;
  --soDiscountImageBg: #8652FF1a;

  --soReservedPriceBg: var(--primaryColor);

  --soInfoAccentTextColor: var(--primaryColor);
  --soInfoOfferTextBg: #F3EEFF;
}